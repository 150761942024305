import differenceInDays from "date-fns/differenceInDays";
import { mmToCm } from "./commonUtils";
import { PatientControlsPatientType } from "./interfaces";
import addDays from "date-fns/addDays";
import format from "date-fns/format";

export const actions = [
  {
    id: "report",
    label: "Report",
    description: "",
  },
  {}
];

export const formatClicksForReport = (clicks: any[]) => {
  return clicks.map(({
    date,
    leftAccidentalClicks,
    rightAccidentalClicks,
    leftActualClicks,
    rightActualClicks,
    leftPainLevel,
    rightPainLevel,
    leftPlannedClicks,
    rightPlannedClicks,
    leftTimeSpent,
    rightTimeSpent,
  }) => ({
    date: date ?? "",
    rightAccidentalClicks: rightAccidentalClicks ?? 0,
    leftAccidentalClicks: leftAccidentalClicks ?? 0,
    rightActualClicks: rightActualClicks ?? 0,
    leftActualClicks: leftActualClicks ?? 0,
    rightPainLevel: rightPainLevel ?? 0,
    leftPainLevel: leftPainLevel ?? 0,
    rightPlannedClicks: rightPlannedClicks ?? 0,
    leftPlannedClicks: leftPlannedClicks ?? 0,
    rightTimeSpent: rightTimeSpent ?? 0,
    leftTimeSpent: leftTimeSpent ?? 0,
  }));
}

const getDefaultValue = (value: any) => value || value === 0 ? `${value}` : 'N/A';

const getReportValue = (value?: number | null) => {
  if (!value || isNaN(value)) return 'N/A';

  const cmValue = mmToCm(value);

  return `${cmValue} cm`;
}

const defaultEmptyLine = [
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
  '-',
];

const treatmentDataHeaders = [
  'Date',
  'Day of lengthening',
  'Accidental Clicks (Left)',
  'Accidental Clicks (Right)',
  'Actual Clicks (Left)',
  'Actual Clicks (Right)',
  'Pain Level (Left)',
  'Pain Level (Right)',
  'Planned Clicks (Left)',
  'Planned Clicks (Right)',
  'Time Spent (Left)',
  'Time Spent (Right)'
];

const WIDTH_PER_SYMBOL = 1.1;
const MAX_NUM_OF_CELLS = treatmentDataHeaders.length;

export const generateReport = (patient: PatientControlsPatientType, clicksData: any): [any, {width: number}[]] => {
  const formattedReport = formatClicksForReport(clicksData);
  const cells = [
    ['Patient id', getDefaultValue(patient.id)],
    ['First name', getDefaultValue(patient.firstName)],
    ['Last name', getDefaultValue(patient.lastName)],
    ['Gender', getDefaultValue(patient.gender)],
    ['Country', getDefaultValue(patient.country)],
    ['Initial Gap', getReportValue(patient.treatmentData.initialGapMm)],
    ['Initial Height', getReportValue(patient.treatmentData.initialHeightMm)],
    ['Left Femur Length', getReportValue(patient.treatmentData.leftFemurLengthMm)],
    ['Left Tibia Length', getReportValue(patient.treatmentData.leftTibiaLengthMm)],
    ['Nail Type', getReportValue(patient.treatmentData.nailTypeMm)],
    ['Operation Type', getDefaultValue(patient.treatmentData.operationType)],
    ['Right Femur Length', getReportValue(patient.treatmentData.rightFemurLengthMm)],
    ['Right Tibia Length', getReportValue(patient.treatmentData.rightTibiaLengthMm)],
    ['Target Distraction', getReportValue(patient.treatmentData.targetDistractionMm)],
    ['Wingspan', getReportValue(patient.treatmentData.wingSpanMm)],
    ['', ''],
    ['Treatment data'],
    treatmentDataHeaders
  ];
  let daysDifference = 1;

  if (patient.surgeryDate && formattedReport[0].date) {
    daysDifference = differenceInDays(new Date(formattedReport[0].date), new Date(patient.surgeryDate)) + 1;

    cells.push([
      `${patient.surgeryDate} (Surgery date)`,
      '1',
      ...defaultEmptyLine,
    ]);

    if (daysDifference <= 20) {
      const surgeryDate = new Date(patient.surgeryDate);
      for (let i = 1; i < daysDifference - 1; i++) {
        const nextEmptyDateToFill = addDays(surgeryDate, i);
        const formattedNextEmptyDateToFill = format(nextEmptyDateToFill, 'yyyy-MM-dd');
  
        cells.push([
          `${formattedNextEmptyDateToFill}`,
          `${i + 1}`,
          ...defaultEmptyLine,
        ]);
      }
    }
  }

  formattedReport.forEach((item, index) => {
    const newRow = [
      getDefaultValue(item.date),
      `${index + daysDifference}`,
      getDefaultValue(item.leftAccidentalClicks),
      getDefaultValue(item.rightAccidentalClicks),
      getDefaultValue(item.leftActualClicks),
      getDefaultValue(item.rightActualClicks),
      getDefaultValue(item.leftPainLevel),
      getDefaultValue(item.rightPainLevel),
      getDefaultValue(item.leftPlannedClicks),
      getDefaultValue(item.rightPlannedClicks),
      getDefaultValue(item.leftTimeSpent),
      getDefaultValue(item.rightTimeSpent),
    ];

    cells.push(newRow);
  });

  const cellsWidth: {width: number}[] = [];

  // Need to generate correct width for each column depending on the longest text that we have in certain cell from that column
  for (let i = 0; i < MAX_NUM_OF_CELLS; i++) {
    let maxColumnWidth = 6;

    cells.forEach(row => {
      const rowCell = row[i];

      if (rowCell && rowCell?.length) {
        if (rowCell.length * WIDTH_PER_SYMBOL > maxColumnWidth) {
          maxColumnWidth = rowCell.length * WIDTH_PER_SYMBOL;
        }
      }
    });

    cellsWidth.push({width: maxColumnWidth});
  }

  return [cells, cellsWidth];
}

import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import classes from "./ApplyTreatmentPlan.module.scss";
import { GET_TREATMENT_TEMPLATES_REQUEST } from "../../../redux/actions";
import { templatesSelector } from "../../../redux/selectors/treatmentPlansSelector";
import { CheckMark } from "../../../assets/icons/CheckMark";
import { IApplyTreatmentPlan } from "../../../utils/interfaces";
import { NoDataPage } from "../NoDataPage/NoDataPage";
import { Button } from "@mui/material";

export const ApplyTreatmentPlan = ({
  selectedPlan,
  setSelectedPlan,
  startDate,
  setStartDate,
  operationType,
  nailTypeMm,
  initialGapMm,
  isViewMode,
  applyTreatmentPlan
}: IApplyTreatmentPlan) => {
  const dispatch = useDispatch();
  const treatmentPlans = useSelector(templatesSelector);

  const isApplyButtonEnabled = useMemo(() => {
    return Boolean(selectedPlan && startDate);
  }, [selectedPlan, startDate]);

  useEffect(() => {
    setSelectedPlan(null);
    dispatch({
      type: GET_TREATMENT_TEMPLATES_REQUEST,
      payload: { operationType, nailTypeMm, initialGapMm }
    });
    // eslint-disable-next-line
  }, [operationType, nailTypeMm, initialGapMm]);

  return (
    <div className={classes.treatmentPlansContainer}>
      <div className={classes.header}>
        <h2>Treatment Plan</h2>
        {!!treatmentPlans?.length && (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              className={classes.dateInput}
              disablePast
              format="dd.MM.yyyy"
              onChange={newValue => setStartDate(newValue)}
              slotProps={{
                textField: {
                  placeholder: "Choose Start Day",
                  variant: "outlined"
                }
              }}
              value={startDate}
            />
          </LocalizationProvider>
        )}
      </div>
      {!!treatmentPlans?.length && (
        <div className={classes.treatmentPlans}>
          {treatmentPlans.map((treatmentPlan: any) => {
            return (
              <div
                className={`${classes.treatmentPlan} ${
                  selectedPlan && selectedPlan === treatmentPlan.id
                    ? classes.selected
                    : ""
                }`}
                key={treatmentPlan.id}
                onClick={() =>
                  setSelectedPlan(treatmentPlan.id)
                }
              >
                <p>{treatmentPlan.name}</p>
                <CheckMark />
              </div>
            );
          })}
        </div>
      )}
      {!treatmentPlans?.length && (
        <NoDataPage
          className={classes.noPlansMessage}
          message={"Suitable plan not found"}
          description={"Please, go to treatment templates and create a new one"}
        />
      )}
      {isViewMode && !!treatmentPlans?.length && (
        <div className={classes.applyContainer}>
          <Button
            className={classes.applyAction}
            disabled={!isApplyButtonEnabled}
            onClick={() => applyTreatmentPlan?.()}
            variant="contained"
          >
            Apply
          </Button>
        </div>
      )}
    </div>
  );
};
